import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import SEO from "../components/seo"
import PageIntro from "../components/PageIntro"
import useGeneralSettings from "../hooks/use-general-settings"

const ContactThankYou = () => {
  const { download_report_url: downloadReportUrl } = useGeneralSettings()
  return (
    <Layout className="contact">
      <SEO title={"Thanks for contacting us"} />
      <Wrapper>
        <section className="page-header">
          <div className="container">
            <div className="inner-container">
              <div className="columns">
                <div className="column column-6">
                  <PageIntro
                    heading={"Contact"}
                    subheading={"Thank you for contacting us."}
                    subtitle={"We will respond to you as soon as we can."}
                    buttons={[
                      {
                        class: "primary",
                        text: "Explore cities",
                        href: "/explore/"
                      },
                      {
                        class: "",
                        text: "Download report",
                        href: downloadReportUrl || "/"
                      }
                    ]}
                  />
                </div>
                <div className="column content-b">
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: rgba(53, 163, 162, 0.05);

  .page-header {
    padding: 2.5rem 0;

    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0;
    }

    .content-b {
      .contact-us-form-container {
        margin-top: 1.5rem;
       
        @media (min-width: ${({ theme }) => theme.desktop}) {
          max-width: 382px;
          float: right;
          margin-top: 0;
        }
      }
    }
  }
`

export default ContactThankYou